var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('add-domain-dialog',{model:{value:(_vm.showAddDomainModal),callback:function ($$v) {_vm.showAddDomainModal=$$v},expression:"showAddDomainModal"}}),_c('v-row',{staticClass:"mb-4"},[_c('v-col',[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.backLandingPage()}}},[_c('v-icon',{attrs:{"color":"primary","dense":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronLeft)+" ")])],1),_c('span',{staticClass:"ml-1 title",style:({ color: ("" + (_vm.isDark ? '#E7E3FCAD' : '#111B21')), 'font-size': '24px', 'font-weight': '700' })},[_vm._v(" "+_vm._s(_vm.$t('Manage Domain'))+" ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-8",attrs:{"cols":"6","md":"3","lg":"2"}},[_c('v-btn',{staticClass:"text-capitalize me-6 white--text",attrs:{"color":"#F15858"},on:{"click":function($event){_vm.showAddDomainModal = true}}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Add Domain ")],1)],1),_c('v-col',{staticClass:"mb-8",attrs:{"cols":"6","md":"4","lg":"3"}},[_c('v-text-field',{staticClass:"shrink",attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Search domain","single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.domainData,"item-key":"id"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: ("" + (_vm.isDark ? '#E7E3FCAD' : '#111B21')) })},[_vm._v(_vm._s(item.id))])]}},{key:"item.siteName",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: ((_vm.isDark ? '#E7E3FCAD' : '#111B21') + " ") })},[_vm._v(_vm._s(item.siteName))])]}},{key:"item.siteAddress",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: ((_vm.isDark ? '#E7E3FCAD' : '#111B21') + " ") })},[_vm._v(_vm._s(item.siteAddress))])]}},{key:"item.connectionType",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: ((_vm.isDark ? '#E7E3FCAD' : '#111B21') + " ") })},[_vm._v(_vm._s(item.connectionType))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"me-3",attrs:{"color":"primary","x-small":"","outlined":""},on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiTrashCan))])],1),_c('v-btn',{staticClass:"me-0",attrs:{"color":"primary","x-small":"","outlined":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEyeOutline))])],1)],1)],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }