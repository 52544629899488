<template>
  <v-container>
    <!-- Heading Text -->
    <h2 class="font-weight-bold mb-12 black--text">Landing Page</h2>

    <!-- Toolbar (buttons & searchbar) -->
    <v-row class="mb-12">
      <!-- Create landing page btn -->
      <v-col cols="12" md="3" xl="2">
        <v-btn
          class="text-capitalize me-6 white--text"
          color="#F15858"
          :block="$vuetify.breakpoint.smAndDown"
          @click="$router.push('/landing-page/create')"
        >
          <v-icon color="white" left>
            {{ icons.mdiPlus }}
          </v-icon>
          Create landing page
        </v-btn>
      </v-col>

      <!-- Upload file btn -->
      <v-col cols="12" md="3" xl="2">
        <v-btn class="text-capitalize" color="#F15858" outlined :block="$vuetify.breakpoint.smAndDown">
          Upload file
        </v-btn>
      </v-col>

      <v-spacer />

      <!-- Manage domain btn -->
      <v-col cols="12" md="3" xl="2" align="end">
        <v-btn
          class="text-capitalize"
          color="#F15858"
          outlined
          :block="$vuetify.breakpoint.smAndDown"
          @click="manageDomain()"
        >
          {{ createdLandingPages.length ? 'Manage Domain' : 'Connect Domain' }}
        </v-btn>
      </v-col>

      <!-- Search bar -->
      <v-col cols="12" md="3">
        <v-text-field
          v-model="search"
          :prepend-inner-icon="icons.mdiMagnify"
          label="Search landing page"
          single-line
          hide-details
          dense
          outlined
          class="shrink"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <!-- Content -->
    <v-row>
      <!-- FTUE -->
      <div v-if="!createdLandingPages.length" class="mx-auto no-landing-page">
        <div>
          <div id="container-image-eclipse">
            <img src="../../assets/images/Landing-eclipse.png" alt="" />
            <img id="image-monitor" src="../../assets/images/Landing-monitor.png" alt="" />
            <img id="image-pc" src="../../assets/images/Landing-pc.png" alt="" />
            <h5>You don't have any landing page yet</h5>
          </div>
        </div>
      </div>

      <!-- Have Created Landing Pages -->
      <v-col v-for="(page, i) in createdLandingPages" v-else :key="i" cols="12" sm="6" md="6" lg="4" xl="3">
        <v-hover v-slot="{ hover }">
          <v-card class="card mx-auto rounded-lg" min-width="330" max-width="330">
            <v-container class="px-6 pt-6 img-container rounded-lg">
              <v-img :src="page.imageUrl + `${page.id + 213}/300/200`" height="160px" class="rounded-t-lg"></v-img>

              <!-- Hover state -->
              <div v-if="hover" class="card-overlay rounded-lg">
                <v-btn
                  color="#F15858"
                  class="text-capitalize me-3 white--text"
                  @click="$router.push('/landing-page/editor')"
                >
                  Edit
                </v-btn>
                <v-btn class="text-capitalize me-3" @click="$router.push('/landing-page/preview')"> View </v-btn>
                <v-btn class="ma-2 trash-btn" @click="deleteItem(page.id)">
                  <v-icon>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </div>
            </v-container>

            <v-card-title class="px-0 py-6">
              <span class="card-title">{{ page.title }}</span>
            </v-card-title>

            <v-card-subtitle class="px-0 pb-5">
              <a :href="page.domain">{{ page.domain }}</a>
            </v-card-subtitle>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiMagnify, mdiPlus, mdiTrashCanOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const search = ref('')

    return {
      icons: { mdiMagnify, mdiPlus, mdiTrashCanOutline },
      search,
    }
  },
  data() {
    return {
      createdLandingPages: [
        { id: 1, domain: 'https://www.marketa.id/', title: 'Happyhomes Chat', imageUrl: 'https://picsum.photos/id/' },
        { id: 2, domain: 'https://www.lpmarketa.com/', title: 'Promotion 7.7', imageUrl: 'https://picsum.photos/id/' },
        {
          id: 3,
          domain: 'https://www.productlp.com/',
          title: 'Landing page product',
          imageUrl: 'https://picsum.photos/id/',
        },
        {
          id: 4,
          domain: 'https://www.productlp.com/',
          title: 'Landing page product',
          imageUrl: 'https://picsum.photos/id/',
        },
        { id: 5, domain: 'https://www.marketa.id/', title: 'Happyhomes Chat', imageUrl: 'https://picsum.photos/id/' },
        { id: 6, domain: 'https://www.lpmarketa.com/', title: 'Promotion 7.7', imageUrl: 'https://picsum.photos/id/' },
      ],

      // createdLandingPages: [],
    }
  },
  methods: {
    manageDomain() {
      this.$router.push('/landing-page/manage-domain')
    },
    deleteItem(id) {
      this.createdLandingPages = this.createdLandingPages.filter(page => page.id !== id)

      // TODO synchronize data to server
    },
  },
}
</script>

<style lang="scss" scoped>
.no-landing-page {
  margin: auto;
  width: 388px;
  height: 300px;
  display: flex;
  flex-direction: row-reverse;
}

.card.v-sheet.v-card {
  border: 0px solid white !important;
  cursor: pointer;
}

.img-container {
  background-color: #f1f1f1;
  position: relative;
}

.card-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.card-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(black, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-btn.trash-btn {
  width: 36px;
  min-width: 36px;
}

#container-image-eclipse {
  position: relative;
}

#image-monitor {
  position: absolute;
  width: 238px;
  height: 144px;
  left: -100px;
  bottom: 30%;
}
#image-pc {
  position: absolute;
  left: 150px;
  bottom: 32%;
}
</style>
